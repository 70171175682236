import { Campaign, TranslatableText } from 'config/constants/types'


export const getAchievementTitle = (campaign: Campaign): TranslatableText => {
  switch (campaign.type) {
    case 'ifo':
      return {
        id: 999,
        fallback: `IFO Shopper: ${campaign.title}`,
        data: {
          name: campaign.title as string,
        },
      }
    default:
      return campaign.title
  }
}

export const getAchievementDescription = (campaign: Campaign): TranslatableText => {
  switch (campaign.type) {
    case 'ifo':
      return {
        id: 999,
        fallback: `Committed more than $5 worth of LP in the ${campaign.title} IFO`,
        data: {
          name: campaign.title as string,
        },
      }
    default:
      return campaign.description
  }
}
