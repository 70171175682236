import BigNumber from 'bignumber.js'
import tokens from './tokens'
import { Token } from './types'

export interface TokenForSwap extends Token {
  user?: {
    balance?: BigNumber
    allowance?: BigNumber
  }
}

export const quoteTokens: TokenForSwap[] = [tokens.abee, tokens.bee, tokens.moon, tokens.bear, tokens.honey]

export const tokenForSwaps: TokenForSwap[] = [tokens.preComb, ...quoteTokens]
