// import tokens from './tokens'
// import farms from './farms'
// import { Ifo, Token } from './types'
import { Ifo } from './types'

/* const cakeBnbLpToken: Token = {
  symbol: farms[1].lpSymbol,
  address: farms[1].lpAddresses,
  decimals: 18,
} */
// 0x03ea7fda77af12fe3c0e34cb0bd31e50c35f2d33
const ifos: Ifo[] = [
  {
    id: 'precomb6',
    address: '0x76Cd01aD41FDce12fFE8f34C90089612d09EaA52',
    isActive: true,
    name: 'Commit HONEY',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '1,003 preCOMB',
    raiseAmount: '15,634 HONEY',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'HONEY',
    currencyAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 16968100,
    releaseBlockNumber: 17256100,
  },
  {
    id: 'precomb7',
    address: '0xA1A794d6Fb1B7a503AF0d1111062c8dE0C2bEfBF',
    isActive: true,
    name: 'Commit BEAR',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '688 preCOMB',
    raiseAmount: '23,879 BEAR',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'BEAR',
    currencyAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 16968100,
    releaseBlockNumber: 17256100,
  },
  {
    id: 'precomb8',
    address: '0x1e58841f15aE6629B483bDA674E603A39c24D1C6',
    isActive: true,
    name: 'Commit MOON',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '750 preCOMB',
    raiseAmount: '54,683 MOON',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'MOON',
    currencyAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    tokenDecimals: 18,
    launchBlockNumber: 16968100,
    releaseBlockNumber: 17256100,
  },
  {
    id: 'precomb9',
    address: '0x8cf68B9cD975B5191c0177cD43336848a62092C2',
    isActive: true,
    name: 'Commit BEE',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '1,101 preCOMB',
    raiseAmount: '243,233 BEE',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'BEE',
    currencyAddress: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    currencyTokenAddress: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    tokenDecimals: 18,
    launchBlockNumber: 16968100,
    releaseBlockNumber: 17256100,
  },
  {
    id: 'precomb10',
    address: '0x7356C100b4f58033871a04F5fc74ab95CAfD627A',
    isActive: true,
    name: 'Commit a.BEE',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '378 preCOMB',
    raiseAmount: '259,384 a.BEE',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'a.BEE',
    currencyAddress: '0xcEA6FC9032fc861BB52326AE660b2d2b3F9D7337',
    currencyTokenAddress: '0xcEA6FC9032fc861BB52326AE660b2d2b3F9D7337',
    tokenDecimals: 18,
    launchBlockNumber: 16968100,
    releaseBlockNumber: 17256100,
  },
  
  {
    id: 'precomb',
    address: '0x8e571e524E3A46B24A2Ac554C8964bdd3c9b496c',
    isActive: true,
    name: '',
    subTitle: '',
    description: '',
    descriptionLink: '',
    saleAmount: '',
    raiseAmount: '',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'HONEY',
    currencyAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 16795000,
    releaseBlockNumber: 16939000,
  },
  
  {
    id: 'precomb1',
    address: '0x8e571e524E3A46B24A2Ac554C8964bdd3c9b496c',
    isActive: true,
    name: 'Commit HONEY',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '1,003 preCOMB',
    raiseAmount: '15,634 HONEY',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'HONEY',
    currencyAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 16795000,
    releaseBlockNumber: 16939000,
  },
  {
    id: 'precomb2',
    address: '0x0710f0F396220498b9D32ceaC32743eC25789599',
    isActive: true,
    name: 'Commit BEAR',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '688 preCOMB',
    raiseAmount: '23,879 BEAR',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'BEAR',
    currencyAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 16795000,
    releaseBlockNumber: 16939000,
  },
  {
    id: 'precomb3',
    address: '0x2D3fD08aA73b8bb22530C014C0b464237E5ef36b',
    isActive: true,
    name: 'Commit MOON',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '750 preCOMB',
    raiseAmount: '54,683 MOON',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'MOON',
    currencyAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    tokenDecimals: 18,
    launchBlockNumber: 16795000,
    releaseBlockNumber: 16939000,
  },
  {
    id: 'precomb4',
    address: '0x86BddE15aB71E7389886dcdb12CB58c54bB52c1c',
    isActive: true,
    name: 'Commit BEE',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '1,101 preCOMB',
    raiseAmount: '243,233 BEE',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'BEE',
    currencyAddress: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    currencyTokenAddress: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    tokenDecimals: 18,
    launchBlockNumber: 16795000,
    releaseBlockNumber: 16939000,
  },
  {
    id: 'precomb5',
    address: '0x0Aab957b4224DfC8d6dCE4541533cCF00A89B6fE',
    isActive: true,
    name: 'Commit a.BEE',
    subTitle: 'Get preCOMB',
    description: '',
    descriptionLink: '',
    saleAmount: '378 preCOMB',
    raiseAmount: '259,384 a.BEE',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'a.BEE',
    currencyAddress: '0xcEA6FC9032fc861BB52326AE660b2d2b3F9D7337',
    currencyTokenAddress: '0xcEA6FC9032fc861BB52326AE660b2d2b3F9D7337',
    tokenDecimals: 18,
    launchBlockNumber: 16795000,
    releaseBlockNumber: 16939000,
  },
  
  {
    id: 'wisteriaswap1',
    address: '0x6F2B991b70Aa848c8f357A9176308b2e47A2459F',
    isActive: false,
    name: 'Commit WBNB',
    subTitle: 'Get WST',
    description: '',
    descriptionLink: '',
    saleAmount: '1,000 WST',
    raiseAmount: '40,000 USD',
    cakeToBurn: '',
    currencyToBurn: '',
    projectSiteUrl: 'https://wisteriaswap.com/',
    currency: 'WBNB',
    currencyAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    currencyTokenAddress: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    tokenDecimals: 18,
    launchBlockNumber: 13554500,
    releaseBlockNumber: 13556680,
  },
  {
    id: 'wisteriaswap2',
    address: '0x3dAaD672ec1B140e46697B50DC99F1EA3e532B5b',
    isActive: false,
    name: 'Commit BEE',
    subTitle: 'Get WST',
    description: '',
    descriptionLink: '',
    saleAmount: '187.5 WST',
    raiseAmount: '7,500 USD',
    cakeToBurn: '7,500 USD',
    currencyToBurn: 'BEE',
    projectSiteUrl: 'https://wisteriaswap.com/',
    currency: 'BEE',
    currencyAddress: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    currencyTokenAddress: '0x1A8d7AC01d21991BF5249A3657C97b2B6d919222',
    tokenDecimals: 18,
    launchBlockNumber: 13554500,
    releaseBlockNumber: 13556680,
  },
  {
    id: 'wisteriaswap3',
    address: '0x181763FC07d64b40424584890f1910D06C1683BD',
    isActive: false,
    name: 'Commit HONEY',
    subTitle: 'Get WST',
    description: '',
    descriptionLink: '',
    saleAmount: '62.5 WST',
    raiseAmount: '2,500 USD',
    cakeToBurn: '2,500 USD',
    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://wisteriaswap.com/',
    currency: 'HONEY',
    currencyAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 13554500,
    releaseBlockNumber: 13556680,
  },
  
  {
    id: 'honeybee1',
    address: '0xCa4c30C1EB454FdDF226ED2a9C663325bD86514C',
    isActive: false,
    name: 'Non-Vesting',
    subTitle: 'Commit HONEY-BNB',
    description: '',
    descriptionLink: '',
    saleAmount: '1,000 BEE',
    raiseAmount: '$300,000',
    cakeToBurn: '$150,000',
    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://bee.honeyfarm.finance',
    currency: 'HONEY-BNB',
    currencyAddress: '0x27418bf2e38e613560d2281db6590f3f6d446259',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 11819500,
    releaseBlockNumber: 11821900,
  },
  {
    id: 'honeybee2',
    address: '0x9b91B2Bb56122eEfC59eb08BDF51354E6620e36d',
    isActive: false,
    name: 'Non-Vesting',
    subTitle: 'Commit BEAR-BNB',
    description: '',
    descriptionLink: '',
    saleAmount: '1,250 BEE',
    raiseAmount: '$375,000',
    cakeToBurn: '$187,500',
    currencyToBurn: 'BEAR',
    projectSiteUrl: 'https://bee.honeyfarm.finance/',
    currency: 'BEAR-BNB',
    currencyAddress: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 11819500,
    releaseBlockNumber: 11821900,
  },
  {
    id: 'honeybee3',
    address: '0x487B14156b896d8841622Dcce3b0149EE649C9E7',
    isActive: false,
    name: 'Non-Vesting',
    subTitle: 'Commit MOON-BNB',
    description: '',
    descriptionLink: '',
    saleAmount: '2,750 BEE',
    raiseAmount: '$825,000',
    cakeToBurn: '$412,500',
    currencyToBurn: 'MOON',
    projectSiteUrl: 'https://bee.honeyfarm.finance/',
    currency: 'MOON-BNB',
    currencyAddress: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
    currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    tokenDecimals: 18,
    launchBlockNumber: 11819500,
    releaseBlockNumber: 11821900,
  },
  {
    id: 'honeybee4',
    address: '0x32DA8A61b6869FdF610A996EAEFdf2BC67A5f249',
    isActive: false,
    name: 'Vesting',
    subTitle: 'Commit HONEY-BNB',
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/bee-pre-iho-vesting-schedule-56efb8247eec',
    saleAmount: '1,467 BEE',
    raiseAmount: '$308,000',
    cakeToBurn: '$154,000',
    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://bee.honeyfarm.finance',
    currency: 'HONEY-BNB',
    currencyAddress: '0x27418bf2e38e613560d2281db6590f3f6d446259',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 11819500,
    releaseBlockNumber: 11821900,
  },
  {
    id: 'honeybee5',
    address: '0x6281Cc216912A863e49B1652712DF356C709cD2e',
    isActive: false,
    name: 'Vesting',
    subTitle: 'Commit BEAR-BNB',
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/bee-pre-iho-vesting-schedule-56efb8247eec',
    saleAmount: '1,833 BEE',
    raiseAmount: '$385,000',
    cakeToBurn: '$192,500',
    currencyToBurn: 'BEAR',
    projectSiteUrl: 'https://bee.honeyfarm.finance/',
    currency: 'BEAR-BNB',
    currencyAddress: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 11819500,
    releaseBlockNumber: 11821900,
  },
  {
    id: 'honeybee6',
    address: '0xCDC51AbD8d150b0d55309c430d173E1ecf573537',
    isActive: false,
    name: 'Vesting',
    subTitle: 'Commit MOON-BNB',
    description: 'Vesting schedule',
    descriptionLink: 'https://medium.com/@honeyfarmchef/bee-pre-iho-vesting-schedule-56efb8247eec',
    saleAmount: '4,033 BEE',
    raiseAmount: '$847,000',
    cakeToBurn: '$423,500',
    currencyToBurn: 'MOON',
    projectSiteUrl: 'https://bee.honeyfarm.finance/',
    currency: 'MOON-BNB',
    currencyAddress: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
    currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
    tokenDecimals: 18,
    launchBlockNumber: 11819500,
    releaseBlockNumber: 11821900,
  },

   
/*
    {
      id: 'robinia',
      address: '0x245e4f861603e4Ad40D7F50752C233BD424C07F0',
      isActive: true,
      name: 'RobiniaSwap',
      subTitle: `ROBINIA Swap IHO`,
      description: `RobiniaSwap is the Yield Farm DeFi and AMM decentralised exchange running on Binance Smart Chain`,
      saleAmount: '3,000 RBS',
      raiseAmount: '$30,000',
      cakeToBurn: '$15,000',
      currencyToBurn: 'MOON',
      projectSiteUrl: 'https://robiniaswap.com/',
      currency: 'MOON-BNB LP',
      currencyAddress: '0x643D3f1C1fA1A7D5D6BDE50Bd3FDD1Cdd8A85692',
      currencyTokenAddress: '0xE8c93310af068aa50bd7bF0ebFa459Df2a02ceba',
      tokenDecimals: 18,
      launchBlockNumber: 11335740,
      releaseBlockNumber: 11338140,
    },
        
  {
    id: 'honeymoon1',
    address: '0x52748dD2C85BBDe0Dd165A3072dB5612010ef5F5',
    isActive: false,
    name: 'MOON',
    subTitle: 'HoneyMoon Pre-IHO: HONEY-BNB LP',
    description: `HoneyFarm Layer3 Pre-IHO. \
  Commit HONEY-BNB LP, Get MOON`,
    saleAmount: '1,804 MOON',
    raiseAmount: '$541,200',
    cakeToBurn: '$270,600',
    currencyToBurn: 'HONEY',
    projectSiteUrl: 'https://honeyfarm.finance/',
    currency: 'HONEY-BNB LP',
    currencyAddress: '0x27418bf2e38e613560d2281db6590f3f6d446259',
    currencyTokenAddress: '0xFa363022816aBf82f18a9C2809dCd2BB393F6AC5',
    tokenDecimals: 18,
    launchBlockNumber: 10593600,
    releaseBlockNumber: 10596000,
  },
  {
    id: 'honeymoon2',
    address: '0xA8Bb9a707b6a8E45129A93792fECd6E0E1458A5b',
    isActive: false,
    name: 'MOON',
    subTitle: 'HoneyMoon Pre-IHO: BEAR-BNB LP',
    description: 'HoneyFarm Layer3 Pre-IHO. Commit BEAR-BNB LP, Get MOON',
    saleAmount: '3,196 MOON',
    raiseAmount: '$958,800',
    cakeToBurn: '$479,400',
    currencyToBurn: 'BEAR',
    projectSiteUrl: 'https://honeyfarm.finance',
    currency: 'BEAR-BNB LP',
    currencyAddress: '0xC5E96F03414DA6E7eA6D2F051D23964C0B80739F',
    currencyTokenAddress: '0xc3EAE9b061Aa0e1B9BD3436080Dc57D2d63FEdc1',
    tokenDecimals: 18,
    launchBlockNumber: 10593600,
    releaseBlockNumber: 10596000,
  },
*/

  //   {
  //     id: 'gotem',
  //     address: '0x4FfEFe9696a577b99ad713caA338666dB2410fe7',
  //     isActive: false,
  //     name: 'GOTEM',
  //     subTitle: `GOTEM is a crowdsourcing DeFi platform enabling private investigations, private security, and humanitarianism
  //     `,
  //     description: `In light of the political climate and the direction the world is taking, it is imperative that there is an outlet for transparency and global welfare. This requires a decentralized platform that cannot be shut down by any authority or special interest group. gotEMs platform brings this demand to  reality. With its decentralized crowdsourcing DeFi platform enabling crowdsourcing and crowdfunding for private investigations, private security, and humanitarian missions. We are on the cusp of a global shift in how people perceive government, safety, money, resources, and the direction of our species. We anticipate that our movement will change the way people interact and go about their personal lives. Hopefully, it will change the world in a meaningful way that will impact future generations for hundreds of years.
  //  Gotem will become the world’s medium for mediation--a platform which would provide recourse, justice and transparency. Not only subject to use by ordinary people, but one which governments can also levy resources from. The final stage being that NGOs depend on the platform to crowdsource (funds and human resources) for their humanitarian missions.`,
  //     launchDate: 'June. 30th',
  //     launchTime: '14:00 UTC',
  //     saleAmount: '2,000,000 GTX',
  //     raiseAmount: '$100,000',
  //     cakeToBurn: '$50,000',
  //     projectSiteUrl: 'https://gotem.io/',
  //     currency: 'LAZY-BUSD LP',
  //     currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
  //     tokenDecimals: 18,
  //     releaseBlockNumber: 8749650,
  //   },
  //    {
  //     id: 'daw',
  //     address: '0x5Beb8131d746b5F56dD8b371C2ec05eeD13278d8',
  //     isActive: false,
  //     name: 'DESWAP',
  //     subTitle: `Deswap is Worlds First Synthetic Stablecoin Lending Protocol build on Polygon Chain`,
  //     description: `Deswap powers the world's first decentralised stablecoin(YAI) built on polygon network, DAW, which is backed by a basket of stablecoins and crypto assets with no centralised control .We are aiming to change the interaction between the lenders and borrowers so that people can exchange assets without seamlessly loosing their funds in the form of higher gas fee. On the Polygon chain, the Deswap Protocol is designed to enable a comprehensive algorithmic money market protocol.`,
  //     launchDate: 'June. 21st',
  //     launchTime: '13:00 UTC',
  //     saleAmount: '40,000 DAW',
  //     raiseAmount: '$100,000',
  //     cakeToBurn: '$50,000',
  //     projectSiteUrl: 'https://deswap.co/',
  //     currency: 'LAZY-BUSD LP',
  //     currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
  //     tokenDecimals: 18,
  //     releaseBlockNumber: 8490450,
  //    },
  //   {
  //     id: 'szoo',
  //     address: '0xcaD3d9891D1a6B44930687ADA3c47395dF074065',
  //     isActive: false,
  //     name: 'SpaceZOO',
  //     subTitle: `SZOO is a progressive deflationary
  //     DeFi token built to succeed on
  //     Binance Smart Chain
  //     `,
  //     description: `SZOO(SpaceZoo Token) is a deflationary token on the Binance Smart Chain (BSC) with a circulating supply of 1,000,000,000,000,000 at launch. BSC chain was launched with an initial 50% of tokens burned and 50% of tokens in circulating supply.
  //     On each transaction, a tax of 2% will be distributed to the holders,  2% of transaction fees will be locked in liquidity pool and a further 2% will be burnt, hence incentivizing holders to hodl and decreasing the supply overtime.
  //     As the supply decreases, the scarcity of the token increases.This inversely-proportional relationship constitutes a supply and demand model.
  //     Some of the transaction taxes is donated monthly to the World Wide Fund for Nature (World Wildlife Fund), which is an official partnership with SpaceZoo.
  //     `,
  //     launchDate: 'June. 16',
  //     launchTime: '13:00 UTC',
  //     saleAmount: '12,500,000,000,000 pSZOO',
  //     raiseAmount: '$100,000',
  //     cakeToBurn: '$50,000',
  //     projectSiteUrl: 'https://spacezoo.net/',
  //     currency: 'LAZY-BUSD LP',
  //     currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
  //     tokenDecimals: 9,
  //     releaseBlockNumber: 8347400,
  //   },
  /* {
    id: 'horizon',
    address: '0x6137B571f7F1E44839ae10310a08be86D1A4D03B',
    isActive: true,
    name: 'Horizon Protocol (HZN)',
    poolBasic: {
      saleAmount: '3,000,000 HZN',
      raiseAmount: '$750,000',
      cakeToBurn: '$375,000',
      distributionRatio: 0.3,
    },
    poolUnlimited: {
      saleAmount: '7,000,000 HZN',
      raiseAmount: '$1,750,000',
      cakeToBurn: '$875,000',
      distributionRatio: 0.7,
    },
    currency: cakeBnbLpToken,
    token: tokens.hzn,
    releaseBlockNumber: 6581111,
    campaignId: '511090000',
    articleUrl: 'https://pancakeswap.medium.com/horizon-protocol-hzn-ifo-to-be-hosted-on-pancakeswap-51f79601c9d8',
    tokenOfferingPrice: 0.25,
    isV1: false,
   
   id: 'gotem',
   address: '0x4FfEFe9696a577b99ad713caA338666dB2410fe7',
   isActive: true,
   name: 'GOTEM',
   subTitle: `GOTEM is a crowdsourcing DeFi platform enabling private investigations, private security, and humanitarianism
   `,
   description: `In light of the political climate and the direction the world is taking, it is imperative that there is an outlet for transparency and global welfare. This requires a decentralized platform that cannot be shut down by any authority or special interest group. gotEMs platform brings this demand to  reality. With its decentralized crowdsourcing DeFi platform enabling crowdsourcing and crowdfunding for private investigations, private security, and humanitarian missions. We are on the cusp of a global shift in how people perceive government, safety, money, resources, and the direction of our species. We anticipate that our movement will change the way people interact and go about their personal lives. Hopefully, it will change the world in a meaningful way that will impact future generations for hundreds of years.
Gotem will become the world’s medium for mediation--a platform which would provide recourse, justice and transparency. Not only subject to use by ordinary people, but one which governments can also levy resources from. The final stage being that NGOs depend on the platform to crowdsource (funds and human resources) for their humanitarian missions.`,
   launchDate: 'June. 30th',
   launchTime: '14:00 UTC',
   saleAmount: '2,000,000 GTX',
   raiseAmount: '$100,000',
   cakeToBurn: '$50,000',
   projectSiteUrl: 'https://gotem.io/',
   currency: 'LAZY-BUSD LP',
   currencyAddress: '0x159804ce45b5303f5BFE894d8A51BfB41Ab296b5',
   tokenDecimals: 18,
   releaseBlockNumber: 8749650,
  },
  {
    id: 'belt',
    address: '0xc9FBedC033a1c479a6AD451ffE463025E92a1d38',
    isActive: false,
    name: 'Belt (BELT)',
    poolUnlimited: {
      saleAmount: '150,000 BELT',
      raiseAmount: '$3,000,000',
      cakeToBurn: '$1,500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.belt,
    releaseBlockNumber: 5493919,
    campaignId: '511080000',
    articleUrl: 'https://pancakeswap.medium.com/belt-fi-belt-ifo-to-be-hosted-on-pancakeswap-353585117e32',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'watch',
    address: '0x55344b55C71Ad8834C397E6e08dF5195cF84fe6d',
    isActive: false,
    name: 'Yieldwatch (WATCH)',
    poolUnlimited: {
      saleAmount: '8,000,000 WATCH',
      raiseAmount: '$800,000',
      cakeToBurn: '$400,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.watch,
    releaseBlockNumber: 5294924,
    campaignId: '511070000',
    articleUrl: 'https://pancakeswap.medium.com/yieldwatch-watch-ifo-to-be-hosted-on-pancakeswap-d24301f17241',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'berry',
    address: '0x5d028cE3435B2bB9AceBfaC599EEbA1ccD63d7dd',
    isActive: false,
    name: 'Berry (BRY)',
    poolUnlimited: {
      saleAmount: '2,000,000 BRY',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.bry,
    releaseBlockNumber: 4750968,
    campaignId: '511060000',
    articleUrl: 'https://pancakeswap.medium.com/berry-bry-ifo-to-be-hosted-on-pancakeswap-b4f9095e9cdb',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'soteria',
    address: '0x9C21123D94b93361a29B2C2EFB3d5CD8B17e0A9e',
    isActive: false,
    name: 'Soteria (wSOTE)',
    poolUnlimited: {
      saleAmount: '1,500,000 wSOTE',
      raiseAmount: '$525,000',
      cakeToBurn: '$262,500',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.wsote,
    releaseBlockNumber: 4086064,
    campaignId: '511050000',
    articleUrl: 'https://pancakeswap.medium.com/soteria-sota-ifo-to-be-hosted-on-pancakeswap-64b727c272ae',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'helmet',
    address: '0xa32509d760ee47Eb2Be96D338b5d69B5FBa4eFEB',
    isActive: false,
    name: 'Helmet.insure (Helmet)',
    poolUnlimited: {
      saleAmount: '10,000,000 Helmet',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.helmet,
    releaseBlockNumber: 3771926,
    campaignId: '511040000',
    articleUrl: 'https://pancakeswap.medium.com/1-000-000-helmet-helmet-ifo-to-be-hosted-on-pancakeswap-3379a2a89a67',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'tenet',
    address: '0xB922aA19A2603A07C6C9ED6c236618C9bac51f06',
    isActive: false,
    name: 'Tenet (TEN)',
    poolUnlimited: {
      saleAmount: '1,000,000 TEN',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.ten,
    releaseBlockNumber: 3483883,
    campaignId: '511030000',
    articleUrl: 'https://pancakeswap.medium.com/tenet-ten-ifo-to-be-hosted-on-pancakeswap-b7e1eb4cb272',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'ditto',
    address: '0x570c9eB19553526Fb35895a531928E19C7D20788',
    isActive: false,
    name: 'Ditto (DITTO)',
    poolUnlimited: {
      saleAmount: '700,000 DITTO',
      raiseAmount: '$630,000',
      cakeToBurn: '$315,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.ditto,
    releaseBlockNumber: 3279767,
    campaignId: '511020000',
    articleUrl: 'https://pancakeswap.medium.com/ditto-money-ditto-ifo-to-be-hosted-on-pancakeswap-342da3059a66',
    tokenOfferingPrice: null,
    isV1: true,
  },
  {
    id: 'blink',
    address: '0x44a9Cc8463EC00937242b660BF65B10365d99baD',
    isActive: false,
    name: 'BLINk (BLK)',
    poolUnlimited: {
      saleAmount: '100,000,000 BLINK',
      raiseAmount: '$1,000,000',
      cakeToBurn: '$500,000',
      distributionRatio: 1,
    },
    currency: cakeBnbLpToken,
    token: tokens.blink,
    releaseBlockNumber: 3279767,
    campaignId: '511010000',
    articleUrl: 'https://medium.com/pancakeswap/1-000-000-ifo-blink-joins-pancakeswap-15841800bdd8',
    tokenOfferingPrice: null,
    isV1: true,
  }, */
]

export default ifos
