import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { PancakeTheme } from '@pancakeswap-libs/uikit/dist/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends PancakeTheme {}
}

const GlobalStyle = createGlobalStyle`
  {
    font-family: 'PT Sans Narrow', sans-serif;
  }
  body {
    background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }
  
@keyframes opacityEffect {
 from{
   opacity:0.2;
 }
 to{
   opacity:1
 }
}
  .tooltip-box{
    .iCkUks[data-popper-placement^="bottom-end"] > .sc-fWCJfs{
        transform:translate3d(190px, 0px, 0px) !important
    }
  }
`

export default GlobalStyle
