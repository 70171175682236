import { useEffect, useState } from 'react'
import { getNftJellyContract } from 'utils/contractHelpers'
import useRefresh from './useRefresh'

const useWinners = (address: string) => {
  const [winners, setWinners] = useState([])
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    async function fetchWinners() {
      const contract = getNftJellyContract(address)
      
      const resCount = await contract.methods.winnerCount().call()
      
//      const winnerList = []
//      for (let i = 0; i < resCount; i++) {
//        const winner = contract.methods.winnerList(i).call()
//        winnerList.push(winner)
//      }
      const winnerList = await contract.methods.winners().call()
      
      if (resCount > 0) {
        setWinners(winnerList)
      }
    }
    fetchWinners()
  }, [address, fastRefresh])

  return winners
}

export default useWinners
