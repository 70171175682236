import { Flex, Text } from '@pancakeswap-libs/uikit'
import React from 'react'
import styled from 'styled-components'
import SwapCard from './components/SwapCard'

const Swap: React.FC = () => {
  return (
    <LiquidityPage className="liquidity-page">
      <Flex justifyContent="center">
        <Text fontSize="28px">
          Migration will be finished on JUNE 22nd. <br />
          Please migrate your HONEY/BEAR/MOON/BEE/a.BEE.
        </Text>
      </Flex>
      <Flex className="page-contents">
        <SwapCard />
      </Flex>
    </LiquidityPage>
  )
}

export default Swap

const LiquidityPage = styled.div`
  .liquidity-page {
    .liquidity-page > div:first-child {
      height: 140px;
    }
  }
  padding-bottom: 300px;
  .page-contents {
    display: flex;
    justify-content: center;
    .lp-card {
      max-width: 516px;
      margin: 20px;
      padding: 20px;
      img.lp-logo {
        height: 36px;
        width: 68px;
        margin: 0 10px;
      }
      .lp-symbol {
        color: rgb(255, 255, 255);
        font-size: 16px;
        font-weight: 600;
        line-height: 1.5;
        font-family: 'PT Sans Narrow', sans-serif;
      }
      .button-wrapper {
        margin: 10px 10px;
        display: flex;
        justify-content: space-around;
        border: #88888888 solid 2px;
        border-radius: 100px;
        & > button {
          margin: 0;
          width: 100%;
        }
        & > button.inactive {
          background: none;
        }
      }
      & > p {
        color: rgb(184, 173, 210);
        font-size: 16px;
        font-weight: 500;
        line-height: 1.5;
        margin-left: 4px;
        font-family: 'PT Sans Narrow', sans-serif;
      }
      .divider {
        margin-top: 15px;
        width: 80%;
        height: 2px;
        background: grey;
      }
      .quote-selector {
        cursor: pointer;
        &:hover {
          opacity: 0.65;
        }
      }
    }
  }
`
