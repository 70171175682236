import React from 'react'
import { CardHeader, Heading, Text, Flex, Image } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'
import useI18n from 'hooks/useI18n'

const Wrapper = styled(CardHeader)<{ isFinished?: boolean; background?: string }>`
  background: ${({ theme }) =>
    theme.colors.backgroundAlt 
  };
  margin-bottom: -40px;
`

const StyledCardHeader: React.FC<{
  earningTokenSymbol: string
  stakingTokenSymbol: string
  isAutoVault?: boolean
  isFinished?: boolean
  isNft?: boolean
  nftLogoUrl?: string
  nftPrice?: string
  title?: string
  titleLink?: string
}> = ({
  earningTokenSymbol,
  stakingTokenSymbol,
  isFinished = false,
  isAutoVault = false,
  isNft,
  nftLogoUrl,
  nftPrice,
  title,
  titleLink,
}) => {
  const TranslateString = useI18n()
  const poolImageSrc = isAutoVault
    ? `cake-cakevault.svg`
    : `${earningTokenSymbol}-${stakingTokenSymbol}.png`.toLocaleLowerCase()
  const isCakePool = earningTokenSymbol === 'CAKE' && stakingTokenSymbol === 'CAKE'
  const earningTokenSymbolDisplay = earningTokenSymbol === 'BNB' ? 'WBNB' : earningTokenSymbol
  // const background = isCakePool ? 'bubblegum' : 'cardHeader'
  const background = 'primary'

  const getHeadingPrefix = () => {
    if (isAutoVault) {
      // vault
      return `${TranslateString(999, 'Auto')}`
    }
    if (isCakePool) {
      // manual cake
      return `${TranslateString(999, 'Manual')}`
    }
    // all other pools
    return `${TranslateString(318, 'Earn')}`
  }

  const getSubHeading = () => {
    if (isAutoVault) {
      return `${TranslateString(999, 'Automatic restaking')}`
    }
    if (isCakePool) {
      return `${TranslateString(999, 'Earn CAKE, stake CAKE')}`
    }
    return `${TranslateString(1070, 'Stake')} ${stakingTokenSymbol}`
  }

  return (
    <Wrapper isFinished={isFinished} background={background}>
      <Flex alignItems="center" justifyContent="space-between">
        {isNft ? (
          <>
            { nftLogoUrl !== '' ?
            <div style={{ width: 100, borderRadius: '0%', overflow: 'hidden' }}>
              <img src={nftLogoUrl} alt={earningTokenSymbolDisplay} height={40} />
            </div>
            :
            <Heading>
              {earningTokenSymbolDisplay}
            </Heading>
            }
            <Flex flexDirection="column">
              <Heading color={isFinished ? 'textDisabled' : 'body'} onClick={() => window.open(titleLink)} style={{textAlign: 'right'}}>
                {title}
              </Heading>
              <Heading color={isFinished ? 'textDisabled' : 'textSubtle'} onClick={() => window.open(titleLink)}>
                {nftPrice}
              </Heading>
            </Flex>
          </>
        ) : (
          <>
            <Flex flexDirection="column">
              <Heading color={isFinished ? 'textDisabled' : 'body'} size="lg">
                {`${getHeadingPrefix()} ${earningTokenSymbolDisplay}`}
              </Heading>
              <Text color={isFinished ? 'textDisabled' : 'textSubtle'}>{getSubHeading()}</Text>
            </Flex>
            <Image src={`/images/pools/${poolImageSrc}`} alt={earningTokenSymbolDisplay} width={64} height={64} />
          </>
        )}
      </Flex>
    </Wrapper>
  )
}

export default StyledCardHeader
