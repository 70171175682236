import React from 'react'
import { Card, CardBody, Text, Button } from '@pancakeswap-libs/uikit'
import styled from 'styled-components'

const Wrapper = styled(Card)`
  width: 80%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: ${({ theme }) => (theme.isDark ? 'rgba(7, 7, 36, 0.8)' : 'rgba(255,255,255,0.8)')};
  button {
    color: ${({ theme }) => (theme.isDark ? '#fff' : '#B25600')};
  }
`

interface StyledWinnerCardProps {
  address: string
}

const StyledWinnerCard: React.FC<StyledWinnerCardProps> = ({ address }) => {
  const displayAddress = `${address.substr(0, 4)}...${address.substr(address.length - 4, 4)}`
  return (
    <Wrapper isActive>
      <CardBody>
        <Text mb="16px">Winner:{displayAddress}</Text>
        <Text>
          Lucky Winner plz contact to any Admin in{' '}
          <Button
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              background: 'none',
              padding: 0,
              margin: 0,
              height: 0,
            }}
            onClick={() => {
              window.open('https://t.me/HoneyFarmChat')
            }}
          >
            official TG
          </Button>
        </Text>
      </CardBody>
    </Wrapper>
  )
}

export default StyledWinnerCard
