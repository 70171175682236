import BigNumber from 'bignumber.js'
import React from 'react'
import { CardBody, Flex, Text, CardRibbon } from '@pancakeswap-libs/uikit'
import UnlockButton from 'components/UnlockButton'
import useI18n from 'hooks/useI18n'
import { getAddress } from 'utils/addressHelpers'
import { useLpTokenPrice, useBusdPriceFromToken } from 'state/hooks'
import useWinners from 'hooks/useWinners'
import { Pool } from 'state/types'
import NFTImageCard from 'views/NFT/components/NFTImageCard'
import StyledWinnerCard from 'views/NFT/components/StyledWinnerCard'
import StyledCard from 'views/Pools/components/PoolCard/StyledCard'
import CardFooter from 'views/Pools/components/PoolCard/CardFooter'
import StyledCardHeader from 'views/Pools/components/PoolCard/StyledCardHeader'
import CardActions from 'views/Pools/components/PoolCard/CardActions'

const PoolCard: React.FC<{ pool: Pool; account: string; path: string; finishedPool?: boolean }> = ({
  pool,
  account,
  finishedPool,
}) => {
  const { sousId, stakingToken, earningToken, isFinished, userData, nftLogoUrl, nftImageUrl, nftPrice, size = 'sm' } = pool
  const TranslateString = useI18n()
  const stakedBalance = new BigNumber(userData?.stakedBalance || 0)
  const accountHasStakedBalance = stakedBalance?.toNumber() > 0
  const lpPrice = useLpTokenPrice(stakingToken.symbol)
  const tokenPrice = useBusdPriceFromToken(stakingToken.symbol)
  const stakingTokenPrice = stakingToken.isLP ? lpPrice.toNumber() : tokenPrice.toNumber()
  const winners = useWinners(getAddress(pool.contractAddress))
  return (
    <StyledCard
      isStaking={!isFinished && accountHasStakedBalance}
      isFinished={isFinished && sousId !== 0}
      finishedPool={finishedPool}
      ribbon={isFinished && <CardRibbon variantColor="textDisabled" text={`${TranslateString(388, 'Finished')}`} />}
      size={size}
    >
      <StyledCardHeader
        earningTokenSymbol={earningToken.symbol}
        stakingTokenSymbol={stakingToken.symbol}
        isFinished={isFinished && sousId !== 0}
        isNft
        nftLogoUrl={nftLogoUrl}
        nftPrice={nftPrice}
        title={earningToken.title}
        titleLink={earningToken.titleLink}
      />
      <CardBody>
        <div style={{ position: 'relative' }}>
          <NFTImageCard nftImageUrl={nftImageUrl} size={size} />
          {
            winners &&
            winners.length > 0 &&
            winners[0] !== '0x0000000000000000000000000000000000000000' &&
            <StyledWinnerCard address={winners[0]} />
          }
        </div>
        <Flex mt="24px" flexDirection="column">
          {account ? (
            <CardActions
              pool={pool}
              stakedBalance={stakedBalance}
              stakingTokenPrice={stakingTokenPrice}
              accountHasStakedBalance={accountHasStakedBalance}
              isNft
            />
          ) : (
            <>
              <Text mb="10px" textTransform="uppercase" fontSize="12px" color="textSubtle" bold>
                {TranslateString(999, 'Start earning')}
              </Text>
              <UnlockButton />
            </>
          )}
        </Flex>
      </CardBody>
      <CardFooter pool={pool} account={account} isNft stakingTokenSymbol={pool.stakingToken.symbol} />
    </StyledCard>
  )
}

export default PoolCard
