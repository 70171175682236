/* eslint-disable no-nested-ternary */
import { ArrowDownIcon, Box, Button, Card, Dropdown, Flex, Heading, Input, Text } from '@pancakeswap-libs/uikit'
import BigNumber from 'bignumber.js'
import UnlockButton from 'components/UnlockButton'
import { quoteTokens, TokenForSwap } from 'config/constants/swap'
import { useSwapApprove } from 'hooks/useApprove'
import { useWeb3React } from '@web3-react/core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { fetchUserSwapTokenData } from 'state/swap'
import { State } from 'state/types'
import styled from 'styled-components'
import { getAddress } from 'utils/addressHelpers'
import useSwap, { useGetRatio } from 'hooks/useSwap'
import { getBep20Contract } from 'utils/contractHelpers'
import useWeb3 from 'hooks/useWeb3'

const SwapCard: React.FC = () => {
  const { account } = useWeb3React()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (account) {
      dispatch(fetchUserSwapTokenData(account))
    }
  }, [account, dispatch])

  const tokenForSwaps = useSelector((state: State) => state.swap.tokenForSwaps)

  const [requestedApproval, setRequestedApproval] = useState(false)
  const [requestedSupply, setRequestedSupply] = useState(false)
  const selectedA = 'preCOMB'
  const [selectedB, setSelectedB] = useState('BEE')
  const tokenA = useMemo(() => findToken(selectedA, tokenForSwaps), [tokenForSwaps, selectedA])
  const tokenB = useMemo(() => findToken(selectedB, tokenForSwaps), [tokenForSwaps, selectedB])

  const [aPerB, setAPerB] = useState<BigNumber>()

  const { onApprove } = useSwapApprove()

  const allApproved = useMemo(() => {
    return new BigNumber(tokenB?.user?.allowance).isGreaterThan(0)
  }, [tokenB])

  const web3 = useWeb3()
  const { onSwap } = useSwap()
  const handleTokenApprove = useCallback(
    async (address: string) => {
      try {
        const lpContract = getBep20Contract(address, web3)
        setRequestedApproval(true)
        await onApprove(lpContract)
        setRequestedApproval(false)
      } catch (e) {
        console.error(e)
        setRequestedApproval(false)
      }
    },
    [onApprove, web3],
  )

  const [tokenAAmount, setTokenAAmount] = useState('0')
  const [tokenBAmount, setTokenBAmount] = useState('0')

  const { addButtonText, addButtonDisabled, insufficientB } = useMemo(() => {
    if (requestedSupply) {
      return { addButtonText: `Wait for Swap...`, addButtonDisabled: true }
    }
    if (new BigNumber(tokenBAmount).isGreaterThan(new BigNumber(tokenB?.user?.balance) ?? 0)) {
      return { addButtonText: `Insufficient ${tokenB.symbol} balance`, addButtonDisabled: true, insufficientB: true }
    }
    if (!new BigNumber(tokenBAmount).isGreaterThan(0)) {
      return { addButtonText: `set ${tokenB.symbol} amount`, addButtonDisabled: true, insufficientB: false }
    }
    return { addButtonText: `Swap`, addButtonDisabled: false }
  }, [requestedSupply, tokenBAmount, tokenB?.user?.balance, tokenB.symbol])

  const onTokenAChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setTokenAAmount(value)
    const bigN = new BigNumber(value)

    if (bigN.isNaN()) {
      return
    }

    setTokenBAmount(bigN.times(aPerB).toFixed())
  }

  const onTokenBChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget
    setTokenBAmount(value)

    const bigN = new BigNumber(value)
    if (bigN.isNaN()) {
      return
    }

    setTokenAAmount(bigN.div(aPerB).toFixed())
  }

  const onSelectTokenBMax = () => {
    setTokenBAmount(tokenB?.user?.balance.toString())

    const bigN = new BigNumber(tokenB?.user?.balance.toString())
    if (bigN.isNaN()) {
      return
    }

    setTokenAAmount(bigN.div(aPerB).toFixed())
  }

  const onSwapClick = async () => {
    try {
      setRequestedApproval(true)
      await onSwap(getAddress(tokenB.address), tokenBAmount)
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
      setRequestedApproval(false)
    }
  }
  const { onGetRatio } = useGetRatio()
  const handleGetRatio = useCallback(
    async (tokenAddress: string) => {
      try {
        const ratio = await onGetRatio(tokenAddress)
        setAPerB(ratio)
      } catch (e) {
        console.error(e)
      }
    },
    [onGetRatio],
  )
  useEffect(() => {
    handleGetRatio(getAddress(tokenB.address))
    setTokenAAmount('0')
    setTokenBAmount('0')
  }, [handleGetRatio, tokenB])

  return (
    <div>
      <Card className="lp-card">
        <Heading size="xl" margin={10} fontFamily={"'Sansita Swashed', cursive"} fontWeight={600}>
          Migration
        </Heading>
        <Text>Migration Phase 1</Text>
        <Flex justifyContent="center">
          <div className="divider" />
        </Flex>

        <Container expanded>
          <Flex flexDirection="column-reverse" height={250} justifyContent="space-between">
            <div>
              <Flex alignContent="center" justifyContent="space-between">
                <Flex>
                  <Text bold mr={10}>
                    To
                  </Text>
                  <img
                    className="token-logo"
                    src={`/images/tokens/${tokenA.symbol.toLowerCase()}.png`}
                    alt={tokenA.symbol}
                  />
                  <p className="token-symbol">{tokenA.symbol}</p>
                </Flex>
                <div>
                  <p className="token-balance">{`Balance : ${
                    tokenA?.user?.balance ? displayBalance(tokenA?.user?.balance.toString()) : '-'
                  }`}</p>
                </div>
              </Flex>
              <InputPanel>
                <InputContainer>
                  <LabelRow>
                    <RowBetween>
                      <Flex alignItems="center" justifyContent="flex-start" width="100%">
                        <StyledInput
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          inputMode="decimal"
                          step="any"
                          min="0"
                          onChange={onTokenAChange}
                          placeholder="0"
                          value={tokenAAmount}
                          style={null}
                        />
                      </Flex>
                    </RowBetween>
                  </LabelRow>
                </InputContainer>
              </InputPanel>
            </div>
            <ArrowDownIcon width={24} stroke="#ffffff" mb={20} mt={20} />
            <div>
              <Flex alignContent="center" justifyContent="space-between">
                <Flex>
                  <Text bold mr={10}>
                    From
                  </Text>
                  <Dropdown
                    position="bottom"
                    target={
                      <Flex
                        style={{
                          padding: '2px',
                          borderRadius: '5px',
                          border: '1px solid #ffffff',
                        }}
                      >
                        <img
                          className="token-logo"
                          src={`/images/tokens/${tokenB.symbol.toLowerCase()}.png`}
                          alt={tokenB.symbol}
                        />
                        <p className="token-symbol">
                          {tokenB.symbol}
                          <span color="#FFFFFF" style={{ fontSize: '8px', margin: '0 4px' }}>
                            ▼
                          </span>
                        </p>
                      </Flex>
                    }
                  >
                    {quoteTokens.map((token, index) => (
                      <Flex
                        key={token.symbol}
                        justifyContent="flex-start"
                        width={75}
                        marginBottom="5px"
                        onClick={() => {
                          setSelectedB(token.symbol)
                        }}
                        className="quote-selector"
                      >
                        <img
                          className="token-logo"
                          src={`/images/tokens/${token.symbol.toLowerCase()}.png`}
                          alt="quote"
                        />
                        <p className="token-symbol">{token.symbol}</p>
                      </Flex>
                    ))}
                  </Dropdown>
                </Flex>
                <div>
                  <p className="token-balance">{`Balance : ${
                    tokenB?.user?.balance ? displayBalance(tokenB?.user?.balance.toString()) : '-'
                  }`}</p>
                </div>
              </Flex>
              <InputPanel>
                <InputContainer>
                  <LabelRow>
                    <RowBetween>
                      <Flex alignItems="center" justifyContent="flex-start" width="100%">
                        <StyledInput
                          pattern="^[0-9]*[.,]?[0-9]*$"
                          inputMode="decimal"
                          step="any"
                          min="0"
                          onChange={onTokenBChange}
                          placeholder="0"
                          value={tokenBAmount}
                          style={insufficientB ? { border: '2px solid red' } : null}
                        />
                        <Button scale="sm" onClick={onSelectTokenBMax} mr="8px">
                          Max
                        </Button>
                      </Flex>
                    </RowBetween>
                  </LabelRow>
                </InputContainer>
              </InputPanel>
            </div>
          </Flex>

          <div className="info-title">
            <p>PRICES</p>
          </div>
          <Card className="info-card">
            <Flex alignContent="center" justifyContent="space-between">
              <p className="token-symbol">1 {tokenB.symbol} =</p>
              <p className="token-balance">
                {aPerB ? displayBalance(aPerB.pow(-1).toFixed()) : '-'} {tokenA.symbol}
              </p>
            </Flex>

            <Flex alignContent="center" justifyContent="space-between">
              <p className="token-symbol">1 {tokenA.symbol} =</p>
              <p className="token-balance">
                {aPerB ? displayBalance(aPerB.toFixed()) : '-'} {tokenB.symbol}
              </p>
            </Flex>
          </Card>

          {!account ? (
            <UnlockButton mt="25px" />
          ) : allApproved ? (
            <Button scale="md" onClick={onSwapClick} mr="8px" mt="25px" disabled>
              {addButtonText}
            </Button>
          ) : (
            <Flex>
              {new BigNumber(tokenB.user?.allowance).isEqualTo(0) && (
                <Button
                  disabled
                  scale="md"
                  width="100%"
                  onClick={() => handleTokenApprove(getAddress(tokenB.address))}
                  mr="8px"
                  mt="25px"
                >
                  {requestedApproval ? 'Wait for Enable...' : `${tokenB.symbol} Enable`}
                </Button>
              )}
            </Flex>
          )}
        </Container>
      </Card>
    </div>
  )
}

export default SwapCard

const displayBalance = (balance: string) => {
  const balanceNumber = Number(balance)
  if (balanceNumber > 0 && balanceNumber < 0.0001) {
    return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 11 })
  }
  if (balanceNumber < 1) {
    return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 6 })
  }

  if (balanceNumber < 10) {
    return balanceNumber.toLocaleString(undefined, { maximumFractionDigits: 4 })
  }
  return balanceNumber.toLocaleString()
}

const findToken = (symbol: string, _tokenForSwaps: TokenForSwap[]) => {
  return _tokenForSwaps.find((t) => t.symbol === symbol)
}

const Container = styled.div<{ expanded }>`
  max-height: ${({ expanded }) => (expanded ? '600px' : '0')};
  overflow: hidden;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: ${({ expanded }) => (expanded ? '10px' : 0)};
  .action-header > h2 {
    color: rgb(255, 255, 255);
    font-size: 25px;
    font-weight: 600;
    line-height: 2;
    font-family: 'PT Sans Narrow', sans-serif;
  }
  .amount > h4 {
    color: rgb(255, 255, 255);
    font-size: 20px;
    font-weight: 600;
    line-height: 2;
    font-family: 'PT Sans Narrow', sans-serif;
  }
  .token-logo {
    height: 24px;
    width: 24px;
    margin-right: 10px;
  }
  .token-balance {
    color: rgb(184, 173, 210);
    font-weight: 600;
    line-height: 1.5;
    font-size: 14px;
  }
  .token-symbol,
  .lp-symbol {
    color: rgb(255, 255, 255);
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    font-family: 'PT Sans Narrow', sans-serif;
  }
  .arrow-down-wrapper svg {
    align-self: center;
    stroke: rgb(184, 173, 210);
    flex-shrink: 0;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  .info-title > p {
    color: rgb(184, 173, 210);
    font-weight: 600;
    line-height: 1.5;
    font-size: 16px;
    margin: 10px 0;
  }
  .info-card {
    background-color: #27262c;
    padding: 14px;
  }
`
const InputPanel = styled.div`
  display: flex;
  flex-flow: column nowrap;
  position: relative;
  border-radius: '20px';
  background-color: ${({ theme }) => theme.colors.backgroundAlt};
  z-index: 1;
`
const InputContainer = styled.div`
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadows.inset};
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 0m;
`
const Row = styled(Box)<{
  width?: string
  align?: string
  justify?: string
  padding?: string
  border?: string
  borderRadius?: string
}>`
  width: ${({ width }) => width ?? '100%'};
  display: flex;
  align-items: ${({ align }) => align ?? 'center'};
  justify-content: ${({ justify }) => justify ?? 'flex-start'};
  padding: ${({ padding }) => padding ?? '0'};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
`
const RowBetween = styled(Row)`
  justify-content: space-between;
`
const StyledInput = styled(Input)`
  width: 80%;
  box-shadow: none;
  margin: 10px 8px;
  padding: 0 8px;
  background-color: #27262c;
`
