import React from 'react'
import styled from 'styled-components'
import { Box } from '@pancakeswap-libs/uikit'

const Outer = styled(Box)<{ background?: string }>`
  // background: ${({ theme, background }) => background || theme.colors.gradients.bubblegum};
  background-image: url('/images/honeybee/farms_top_bg.png');
  background-repeat: no-repeat;
  height: 280px;
  background-size: cover;
  padding-top: 16px;
  padding-bottom: 32px;
  padding-left: 100px;
  & > h1:first-child,
  & > div > div > h1:first-child {
    font-family: 'Cute Bold';
  }
`

const PageHeader: React.FC<{ background?: string }> = ({ background, children, ...props }) => (
  <Outer background={background} {...props}>
    {children}
  </Outer>
)

export default PageHeader
