import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
  
  // NFTJelly
  {
    sousId: 105,
    stakingToken: tokens.bee,
    earningToken: tokens.honey_nfh_1,
    contractAddress: {
      97: '',
      56: '0x57e5718250412Cf69b4280F032483411908224b9',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: 'images/pools/bayc_logo.png',
    nftImageUrl: '/images/pools/bayc.png',
    nftPrice: '$250,000',
    depositFee: '0.1 BEE',
    minDeposit: 1,
    size: 'lg',
  },
  {
    sousId: 112,
    stakingToken: tokens.bee,
    earningToken: tokens.honey_nfh_8,
    contractAddress: {
      97: '',
      56: '0x100187903167cCc0E434c2552Fd55eE0417b2Af7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: 'images/pools/metasaurs_logo.png',
    nftImageUrl: '/images/pools/metasaurs_3695.png',
    nftPrice: '$1,500',
    depositFee: '0.1 BEE',
    minDeposit: 1,
    size: 'sm',
  },
  {
    sousId: 110,
    stakingToken: tokens.bee,
    earningToken: tokens.honey_nfh_6,
    contractAddress: {
      97: '',
      56: '0x45F91d28aC399c8ccF764368900531575E4d0Cd5',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: '',
    nftImageUrl: '/images/pools/yolonaut.png',
    nftPrice: '$2,000',
    depositFee: '0.05 BEE',
    minDeposit: 0.5,
    size: 'sm',
  },
  {
    sousId: 111,
    stakingToken: tokens.bear,
    earningToken: tokens.honey_nfh_7,
    contractAddress: {
      97: '',
      56: '0x416c59a2daaC8cC3e7c704C6F26016Ee07328E03',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: '',
    nftImageUrl: '/images/pools/yoloverseloyalty.png',
    nftPrice: '$600',
    depositFee: '0.5 BEAR',
    minDeposit: 5,
    size: 'sm',
  },
  {
    sousId: 107,
    stakingToken: tokens.moon,
    earningToken: tokens.honey_nfh_2,
    contractAddress: {
      97: '',
      56: '0x92A278552D34623c87A9958E1eDb552060e950f0',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: 'images/pools/metasaurs_logo.png',
    nftImageUrl: '/images/pools/metasaurs_5471.png',
    nftPrice: '$2,000',
    depositFee: '0.5 MOON',
    minDeposit: 5,
    size: 'sm',
  },
  {
    sousId: 106,
    stakingToken: tokens.bee,
    earningToken: tokens.honey_nfh_3,
    contractAddress: {
      97: '',
      56: '0xF1F9AE0e4c31E661661FFFFaB0fd7f198c79fD34',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: 'images/pools/metasaurs_logo.png',
    nftImageUrl: '/images/pools/metasaurs_3696.png',
    nftPrice: '$8,600',
    depositFee: '0.05 BEE',
    minDeposit: 0.5,
    size: 'sm',
  },
  {
    sousId: 108,
    stakingToken: tokens.honey,
    earningToken: tokens.honey_nfh_4,
    contractAddress: {
      97: '',
      56: '0x1fdcC057084e75b259FE6abD0d03770bE682d795',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: '',
    nftImageUrl: '/images/pools/klaypunks_2922.png',
    nftPrice: '$320',
    depositFee: '0.5 HONEY',
    minDeposit: 5,
    size: 'sm',
  },
  {
    sousId: 109,
    stakingToken: tokens.bear,
    earningToken: tokens.honey_nfh_5,
    contractAddress: {
      97: '',
      56: '0xD018a16856De25995D68F9c9Fa2E8DDd83C955f7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1',
    sortOrder: 1,
    isFinished: false,
    nftLogoUrl: '',
    nftImageUrl: '/images/pools/klaypunks_2793.png',
    nftPrice: '$400',
    depositFee: '0.5 BEAR',
    minDeposit: 5,
    size: 'sm',
  },
  
  // RoyalJelly
  {
    sousId: 34,
    stakingToken: tokens.bee,
    earningToken: tokens.busd,
    contractAddress: {
      97: '',
      56: '0x9044916Ee8195c2CceFFDbb57C3c1Bc2A46EE9C7',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0260416',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 33,
    stakingToken: tokens.bee,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0xFe1264a9D69EB1f005724a94CF689523a05e6b61',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000006943',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 32,
    stakingToken: tokens.bee,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0x15e9932806991e6d5c3Ff7e3998DDAAD35F82Af4',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000055628',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 31,
    stakingToken: tokens.bee,
    earningToken: tokens.eth,
    contractAddress: {
      97: '',
      56: '0xA5801d9affb1798C5838Ec8e28B8C02F3872BB56',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000557',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 30,
    stakingToken: tokens.bee,
    earningToken: tokens.dep,
    contractAddress: {
      97: '',
      56: '0xEF3A721f3da1D07A4cdfC863FA10e0D440019212',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '1.617',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 29,
    stakingToken: tokens.bee,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0x9C00208A262ab431fDf7Bb31Aa9a0B238d0bf655',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000007057',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 28,
    stakingToken: tokens.bee,
    earningToken: tokens.ada,
    contractAddress: {
      97: '',
      56: '0x179a60fB2ae172b6Cc91116389ce9021cAEC4728',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.02203',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 27,
    stakingToken: tokens.bee,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0xF77a6f15fe2a2C59aac20cF0980E57a9f75A6FBC',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00005525',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 26,
    stakingToken: tokens.bee,
    earningToken: tokens.ltc,
    contractAddress: {
      97: '',
      56: '0x25eCD12F500438b566834cF70a31609257F7C212',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000195949',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 25,
    stakingToken: tokens.bee,
    earningToken: tokens.squid,
    contractAddress: {
      97: '',
      56: '0xcBB86aef7A647AE45C7605f14F80147061128d9e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.61736',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 24,
    stakingToken: tokens.bee,
    earningToken: tokens.link,
    contractAddress: {
      97: '',
      56: '0x27966a7892D5991Cd63117be2CE8d44Bb7293D75',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00155',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 23,
    stakingToken: tokens.bee,
    earningToken: tokens.dot,
    contractAddress: {
      97: '',
      56: '0x84119d3b144AFfF4E79605fa17eF07542597fB47',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00106379',
    sortOrder: 1,
    isFinished: false,
  },
  {
    sousId: 22,
    stakingToken: tokens.bee,
    earningToken: tokens.pacoca,
    contractAddress: {
      97: '',
      56: '0xf77eE26C478f680Fe3fFfEc445f8813A51553DBc',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.15632',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 21,
    stakingToken: tokens.bee,
    earningToken: tokens.usdt,
    contractAddress: {
      97: '',
      56: '0xEd4507C699Ea71976b025C69aeF73aDA36417A79',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.03306',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 20,
    stakingToken: tokens.bee,
    earningToken: tokens.busd,
    contractAddress: {
      97: '',
      56: '0x60c1196445FfFD2e248Bf1762EFA4Ab133D78502',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.03306',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 19,
    stakingToken: tokens.bee,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0xF3f4F7f26bd0E46f0d2f76E7e33419974c77ebc2',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00000086159',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 18,
    stakingToken: tokens.bee,
    earningToken: tokens.eth,
    contractAddress: {
      97: '',
      56: '0x8BB5a5B4cDb3C3c4C302191FBfbd32Ea1c43de40',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000119457',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 17,
    stakingToken: tokens.bee,
    earningToken: tokens.btt,
    contractAddress: {
      97: '',
      56: '0x2E929e5e24f6864115C876d1aB68c767e98fd438',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '8.5291629',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 16,
    stakingToken: tokens.bee,
    earningToken: tokens.avax,
    contractAddress: {
      97: '',
      56: '0x47986428295c6b79490382E2D0E6C327D14B5384',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00052308',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 15,
    stakingToken: tokens.bee,
    earningToken: tokens.squid,
    contractAddress: {
      97: '',
      56: '0xB44B2e9Af10cf7781B80D273a0BD5AEe5Cf77443',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0506524',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 14,
    stakingToken: tokens.bee,
    earningToken: tokens.xrp,
    contractAddress: {
      97: '',
      56: '0x576bF46d3C4DfbC631292f302B48176bC1ca003e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0556921',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 13,
    stakingToken: tokens.bee,
    earningToken: tokens.link,
    contractAddress: {
      97: '',
      56: '0x88b0280e23b9d490A709Ab24444bF3F143578574',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0019351',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 12,
    stakingToken: tokens.bee,
    earningToken: tokens.dot,
    contractAddress: {
      97: '',
      56: '0x53c08ee0b3b52f8c03922ad85bdf4a01ff9f211e',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00138773',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 11,
    stakingToken: tokens.bee,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0xDb82c980c55E35997371d4D8F667cd0F836a6c77',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00014528',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 10,
    stakingToken: tokens.bee,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0x49B4d42186f4f3171afb768347512b3d3B744ee8',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0000011022',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 9,
    stakingToken: tokens.bee,
    earningToken: tokens.eth,
    contractAddress: {
      97: '',
      56: '0x4dfC4d894CC4Fb21c4E6FC3Da1Bb70D450e3bA3c',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000016937',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 8,
    stakingToken: tokens.bee,
    earningToken: tokens.ada,
    contractAddress: {
      97: '',
      56: '0x85e5DBDCCA72c5ad75de9Fa79993024b59012115',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.031855',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 7,
    stakingToken: tokens.bee,
    earningToken: tokens.busd,
    contractAddress: {
      97: '',
      56: '0xb0454E6456f2B491c25C73b2eACcA2D368d96600',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0868',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 6,
    stakingToken: tokens.bee,
    earningToken: tokens.wbnb,
    contractAddress: {
      97: '',
      56: '0x4184E5C48044A7e311e42b7F5B96ca5C30637389',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0004249',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 4,
    stakingToken: tokens.bee_bnb,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0xD3C1EF1D883b531A94107645f072F8ab2E5e1B63',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000003205',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 5,
    stakingToken: tokens.bee_busd,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0x7c0FFB416068C6f74Dca9006Fe15FbDaeF368380',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000003205',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 1,
    stakingToken: tokens.bee,
    earningToken: tokens.btcb,
    contractAddress: {
      97: '',
      56: '0x08D69B39eaEF259Dc7d7338E5533D84428C76DBD',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.000003205',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 2,
    stakingToken: tokens.bee,
    earningToken: tokens.eth,
    contractAddress: {
      97: '',
      56: '0x4a0b5226E48Df975B51E1386E990924f4b1E18Be',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.00005113',
    sortOrder: 1,
    isFinished: true,
  },
  {
    sousId: 3,
    stakingToken: tokens.bee,
    earningToken: tokens.ada,
    contractAddress: {
      97: '',
      56: '0xa9894b9B0a5344dB2730E2F837ACb7FD5ca6D4Ef',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '0.0892498',
    sortOrder: 1,
    isFinished: true,
  },
]

export default pools
