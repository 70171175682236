import { TokenForSwap } from 'config/constants/swap'
import { useCallback } from 'react'
import { useAppDispatch } from 'state'
import { fetchUserSwapTokenData } from 'state/swap'
import { useWeb3React } from '@web3-react/core'
import BigNumber from 'bignumber.js'
import { migrateToken } from 'utils/callHelpers'
import { useMigration } from './useContract'

const useSwap = () => {
  const dispatch = useAppDispatch()
  const { account } = useWeb3React()
  const migrationContract = useMigration()

  const handleSwap = useCallback(
    async (tokenAddress: string, amount: string) => {
      const txHash = await migrateToken(migrationContract, tokenAddress, amount, account)
      dispatch(fetchUserSwapTokenData(account))
      console.info(txHash)
    },
    [account, dispatch, migrationContract],
  )

  return { onSwap: handleSwap }
}
export const useGetRatio = () => {
  const migrationContract = useMigration()

  const handleGetRatio = useCallback(
    async (tokenAddress: string) => {
      const result = await migrationContract.methods.exchangeRatio(tokenAddress).call()
      return new BigNumber(result).div(10 ** 4)
    },
    [migrationContract.methods],
  )

  return { onGetRatio: handleGetRatio }
}

export default useSwap
// PAN->KLAY: swapExactTokensForKLAYSupportingFeeOnTransferTokens
// PAN->USDT: swapExactTokensForTokensSupportingFeeOnTransferTokens
// KLAY->PAN: swapExactKLAYForTokensSupportingFeeOnTransferTokens
// USDT->PAN:swapExactTokensForTokensSupportingFeeOnTransferTokens

// function swapExactTokensForTokensSupportingFeeOnTransferTokens ( uint256 amountIn, uint256 amountOutMin, address[] path, address to, uint256 deadline ) external;
// function swapExactKLAYForTokensSupportingFeeOnTransferTokens ( uint256 amountOutMin, address[] path, address to, uint256 deadline ) external;
// function swapExactTokensForKLAYSupportingFeeOnTransferTokens ( uint256 amountIn, uint256 amountOutMin, address[] path, address to, uint256 deadline ) external;
