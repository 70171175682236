/* eslint-disable no-param-reassign */
import MulticallABI from 'config/abi/Multicall.json'
import { createSlice } from '@reduxjs/toolkit'
import BigNumber from 'bignumber.js'
import erc20Abi from 'config/abi/erc20.json'
import { SwapState } from 'state/types'
import { getAddress, getMulticallAddress, getMigrationAddress } from 'utils/addressHelpers'
import multicall from 'utils/multicall'
import { tokenForSwaps } from '../../config/constants/swap'

const initialState: SwapState = {
  tokenForSwaps,
}

export const swapSlice = createSlice({
  name: 'Swaps',
  initialState,
  reducers: {
    setTokenForSwaps: (state, action) => {
      state.tokenForSwaps = action.payload
    },
  },
})

// Actions
export const { setTokenForSwaps } = swapSlice.actions

export default swapSlice.reducer

export const fetchUserSwapTokenData = (account: string) => async (dispatch) => {
  const promises = tokenForSwaps.map(async (token) => {
    const calls = [
      {
        address: getAddress(token.address),
        name: 'allowance',
        params: [account, getMigrationAddress()],
      },
      {
        address: getAddress(token.address),
        name: 'balanceOf',
        params: [account],
      },
    ]

    const [_allowance, _balanceOf] = await multicall(erc20Abi, calls)
    return {
      address: token.address,
      allowance: new BigNumber(_allowance[0].toString()).toJSON(),
      balanceOf: new BigNumber(_balanceOf[0].toString()).div(10 ** token.decimals).toJSON(),
    }
  })

  try {
    const data = await Promise.all(promises)

    dispatch(
      setTokenForSwaps(
        tokenForSwaps.map((token, i) => {
          const userData = data[i]

          return {
            ...token,
            user: {
              allowance: userData.allowance,
              balance: userData.balanceOf,
            },
          }
        }),
      ),
    )
  } catch (e) {
    console.error(e)
  }
}
