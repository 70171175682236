import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import sleepy from './sleepy_.gif';
// import sleepy from '/images/'
import "./css/globals.css"
import "./css/honeyfarm-raendingpeiji-web.css"
import "./css/styleguide.css"

export const openTime = '2021-06-30T18:00:00';

const Landing = () => {
  const [timer, setTimer] = useState('Start timer!!');
  const [day, setDay] = useState('00');
  const [hour, setHour] = useState('00');
  const [min, setMin] = useState('00');
  const [sec, setSec] = useState('00');
  useEffect(() => {
    setInterval(() => {
      const targetTime = new Date(openTime);
      const _now = Date.now();
      const leftTime = targetTime.getTime() - _now;
      const dateObj = new Date(leftTime);
      const formattedTime = `
      ${(dateObj.getUTCDate() - 1).toString().padStart(2, '0')}d ${dateObj.getUTCHours().toString().padStart(2, '0')}h ${dateObj.getUTCMinutes().toString().padStart(2, '0')}m ${dateObj.getUTCSeconds().toString().padStart(2, '0')}s`;
      setTimer(formattedTime);
      setDay((dateObj.getUTCDate() - 1).toString().padStart(2, '0'));
      setHour(dateObj.getUTCHours().toString().padStart(2, '0'));
      setMin(dateObj.getUTCMinutes().toString().padStart(2, '0'));
      setSec(dateObj.getUTCSeconds().toString().padStart(2, '0'));
    }, 1000);
  }, []);
  
  return (
    <div className="container-center-horizontal">
      <div className="honeyfarm-raendingpeiji-web screen">
        <div className="group-621-Fpil7x">
          <div className="group-614-KBlgyE"><img className="group-611-oY6AC8" src="/images/Landing/group-611@1x.png" alt=""/></div>
          <div className="group-619-KBlgyE">
            <SWTimer>
              <STimer>09:00 UTC</STimer>
              {false &&
              <STimer>{timer}</STimer>
              }
              {false &&
              <div>
              <STimer>{day}</STimer>
              <STimer>{hour}</STimer>
              <STimer>{min}</STimer>
              <STimer>{sec}</STimer>
              </div>
              }
            </SWTimer>
          </div>
          <div className="group-620-KBlgyE2">
            <a href="https://twitter.com/HoneyFarmFi">
              <img className="group-620-KBlgyE" src="/images/Landing/twitter.png" alt="" />
            </a>
            <a href="https://t.me/HoneyFarmChat">
              <img className="group-620-KBlgyE" src="/images/Landing/telegram.png" alt="" />
            </a>
            <a href="https://medium.com/@honeyfarmchef">
              <img className="group-620-KBlgyE" src="/images/Landing/medium.png" alt="" />
            </a>
            <a href="https://honeyfarm.gitbook.io/honeyfarm/">
              <img className="group-620-KBlgyE" src="/images/Landing/gitbook.png" alt="" />
            </a>
          </div>
        </div>
        <div className="group-623-Fpil7x">
          <div className="group-613-NBFikR">
            <img className="group-609-07hqRu" src="/images/Landing/group-609@1x.png" alt="" /><img
              className="asset-10-1-07hqRu"
              src="/images/Landing/asset-10-1@2x.png"
              alt=""
            /><img className="asset-10-2-07hqRu" src="/images/Landing/asset-10-2@2x.png" alt="" />
          </div>
        </div>
        <img className="asset-43x-1-Fpil7x" src="/images/Landing/asset-4-3x-1@1x.png" alt="" /><img
          className="asset-154x-Fpil7x"
          src="/images/Landing/asset-15-4x@1x.svg"
          alt=""
        />
      </div>
    </div>
  );
  
  /*
  return (
    <SWrapper>
      <SParent>
        <SHeader>
          <SLogo src="/images/lazymint_ticker.svg" />
          <p>HoneyFarm</p>
        </SHeader>
        <Overlay>
          <OverlayTitle>HoneyFarm</OverlayTitle>
          <OverlayContents>
            <p>Yield farming project</p> <p>on Bianace Smart Chain</p>
          </OverlayContents>
          <SWTimer>
            <STimer>{timer}</STimer>
          </SWTimer>
          { false &&
          <OverlayContents>
          Server is under maintenance
          </OverlayContents>
          }
          { false &&
          <SWButton>
            <SButton>Go to App</SButton>
          </SWButton>
          }
          <div>
            <SVImage>
              <a href="https://github.com/lazymint" target="blank">
                <SImage src="/images/github.svg" />
              </a>
              <a href="https://twitter.com/LazyMintFi" target="blank">
                <SImage src="/images/twitter.svg" />
              </a>
              <a href="https://t.me/LazyMintChat" target="blank">
                <SImage src="/images/telegram.svg" />
              </a>
              <a href="https://medium.com/@LazyMint" target="blank">
                <SImage src="/images/medium.svg" />
              </a>
              <a href="https://www.reddit.com/r/LazyMintOfficial/" target="blank">
                <SImage src="/images/reddit.svg" />
              </a>
              <a href="https://docs.lazymint.finance" target="blank">
                <SImage src="/images/books.svg" />
              </a>
            </SVImage>
          </div>
        </Overlay>
        <div>
          <SBackground src={sleepy} />
        </div>
      </SParent>
    </SWrapper>
  );
  */
};

export default Landing;

const SWTimer = styled.div`
  width: 100%;
  height: 100px;
  margin-bottom: 8px;
  text-align: center;
`;

const STimer = styled.span`
  width: 200px;
  padding: 10px;
  margin: 20px;
  font-size: 30px;
  // color: ${({ theme }) => theme.colors.primary};
  color: rgb(84, 32, 15);
  background-color: rgb(255, 203, 142);
  border-radius: 20px;
  font-weight: bold;
`;
