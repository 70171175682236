import React from 'react'

interface NFTImageCardProps {
  nftImageUrl: string
  size?: string
}

const NFTImageCard: React.FC<NFTImageCardProps> = ({ nftImageUrl, size }) => {
  const w = size === 'sm' ? 300 : 480
  const h = size === 'sm' ? 250 : 400
  return <img src={nftImageUrl} alt="" style={{ width: w, height: h, borderRadius: 20 }} />
}

export default NFTImageCard
